import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type {
  InstitutionalPageQueryQuery,
  InstitutionalPageQueryQueryVariables,
} from '@generated/graphql'
import { Image } from 'src/components/ui/Image'
import { ProductShelf } from 'src/components/sections/ProductShelf'
import Newsletter from 'src/components/sections/Newsletter'
import Section, { SectionHeader } from 'src/components/sections/Section'
import Card from 'src/components/common/Institutional/Card'
import { Title } from 'src/styles/pages/natal'

const Page: FCC<
  PageProps<InstitutionalPageQueryQuery, InstitutionalPageQueryQueryVariables>
> = (props) => {
  const {
    data: { site },
    location: { host, pathname },
  } = props

  const title = 'Natal'

  const canonical =
    host !== undefined
      ? `https://${host}${pathname.replace(/\/$/, '')}`
      : pathname.replace(/\/$/g, '')

  const cmsInfo = [
    {
      title: '<h3>O Presente de quem você ama está aqui! 🎁 </h3>',
      content:
        '<p>ARRASE no mimo de Natal com nossos produtos! Encontrar o presente perfeito pra aquele alguém nem sempre é fácil, por isso temos uma seção especial pra você tornar este Natal muito mais memorável e cheio de paixão!</p>\n\t\t\t<p>Compre por Seleção: 🛒 <u><span style="background-color: #fb005d"><a href="https://www.miess.com.br/Compre-por-selecao" style="color: #ffffff">clique aqui</a></span></u></p>',
    },
    {
      title: '<h3>Mas quando vai chegar?</h3>',
      content:
        '<p>Receba seu pedido no mesmo dia selecionando Entrega Rápida (Motoboy)! Essa forma de envio atende algumas regiões de SP, caso atenda o seu endereço, a opção irá aparecer\n\t\t\tdiretamente na sacola de compras.Pedidos com o pagamento aprovado até as 10h serão entregues no mesmo dia até as 22h, pedidos aprovados após as 10h serão entregues\n\t\t\tno próximo dia útil, até as 22h</p>\n\t\t\t<p>É de fora de SP? Sem problemas! Saiba mais sobre as opções de entrega rápida que temos para você <u><span style="background-color: #fb005d"><a href="https://www.miess.com.br/institucionais/PosCompra/PrazoEntrega" style="color: #ffffff">clicando aqui</a></span></u></p>',
    },
    {
      title: '<h3>Quais são os canais de atendimento da Miess?</h3>',
      content:
        '<p>Você pode tirar suas dúvidas sobre produtos e preços pelo telefone (11) 4810-6810. Para solicitações referentes a pedidos, entre em contato e-mail sac@miess.com.br.</p>\n            <p>Nosso horário de atendimento é de Segunda a Sexta das 8h às 18hs e Sábados das 8h às 12h.</p>',
    },
  ]

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: '/natal',
            name: title,
            position: 1,
          },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      <Image
        src="https://miess.vtexassets.com/arquivos/natal-1.jpg"
        alt="Natal 1"
        width={3543}
        height={1283}
        style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
        loading="eager"
      />

      <ProductShelf
        clusterId={157}
        shelfTitle="Os produtos perfeitos para o seu Natal pegar 🔥"
        productsQuantity={10}
      />

      <ProductShelf
        clusterId={189}
        shelfTitle="Os Destaques do Mês 🎄"
        productsQuantity={10}
      />

      <Image
        src="https://miess.vtexassets.com/arquivos/natal-2.jpg"
        alt="Natal 2"
        width={3543}
        height={1283}
        style={{ width: '100%', height: 'auto' }}
        loading="eager"
      />

      <Section marginTop="64px">
        <SectionHeader title={<></>}>
          <Title>
            FAQ de <b>Natal</b>{' '}
            <span role="img" aria-label="Natal">
              🎄
            </span>
          </Title>
        </SectionHeader>
        {cmsInfo.map(({ title: cardTitle, content }, index) => (
          <Card key={index} title={cardTitle} content={content} />
        ))}
      </Section>

      <Newsletter />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query NatalPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`
Page.displayName = 'Page'

export default mark(Page)
