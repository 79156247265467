import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2`
  font-family: 'Baloo';
  font-size: 35px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 100%;

  b {
    color: #91153a;
    font-weight: normal;
  }
`
